@tailwind base;
@tailwind components;
@tailwind utilities;

@import "themes/doubtnut/dark.css";
@import "themes/doubtnut/light.css";
@import "themes/allen/dark.css";
@import "themes/allen/light.css";

@layer base {
  h1, h2, h3, h4, h5, h6{
    color: var(--text-default-title, --text-default-body, --app-color-body) !important;
  }
  body{
    background-color: var(--bg-primary, --app-color-body-bg);
    color: var(--text-default-body, --app-color-body);
  }
}

@layer components {
  section {
    @apply my-4 w-full first:mt-0;
  }
  .body-fluid section {
    @apply px-4 lg:px-0;
  }
  .body-content section {
    @apply px-4 lg:px-0;
  }
  .body-fluid {
    @apply prose prose-headings:font-medium max-w-screen-4k dark:prose-invert mx-auto font-light marker:prose-ul:text-body marker:prose-ol:text-body mt-4;
  }
  .body-content {
    @apply body-fluid dark:prose-invert md:max-w-screen-sm lg:max-w-[920px] xl:max-w-screen-lg 2xl:max-w-screen-xl mt-4;
  }
  .text-disabled {
    @apply text-gray-400;
  }
  .hover-pop {
    @apply hover:scale-105 hover:duration-300;
  }
  .btn {
    @apply px-5 py-3 shrink-0 font-medium rounded-full flex items-center justify-center;
  }
  .btn-primary {
    @apply btn border-transparent border-2 shadow-md bg-primary text-white hover-pop;
  }
  .btn-secondary {
    @apply btn border-transparent border-2 text-accent dark:text-body hover-pop;
  }
  .btn-tertiary {
    @apply btn text-accent hover-pop;
  }
  .btn-disabled {
    @apply btn bg-primary-light text-disabled;
  }
  .link {
    @apply text-link no-underline hover:underline font-medium;
  }
  .card {
    @apply  p-4 rounded-lg bg-secondary dark:text-body;
  }
  .pill {
    @apply px-4 py-2 rounded-full text-sm font-medium w-max;
  }
  .pill.pillShadow {
    @apply bg-secondary ;
  }
  .pill.pillShadow.selected {
    @apply bg-secondary-dark text-accent;
  }
  .pill.pillShadow.clickable {
    @apply hover:bg-secondary-dark;
  }
  .pill.pillBorder {
    @apply bg-secondary border-2 font-bold border-border-brand-active text-body hover:bg-sheer-fills-inverted-20 active:bg-sheer-fills-40;
  }
  /* .pill.pillBorder.selected {
    @apply bg-primary border-2 border-primary-dark;
  }
  .pill.pillBorder.clickable {
    @apply hover:bg-primary-dark hover:text-white;
  } */
  .h1-title {
    @apply text-3xl;
  }
  .h2-title {
    @apply text-2xl;
  }
  .h3-title {
    @apply text-xl;
  }
  .dropdown:hover > .dropdown-content {
    @apply block;
  }
  .input {
    @apply w-full h-12 px-2 bg-secondary-light border border-gray-300 text-body rounded-xl shadow-sm focus:ring-primary focus:border-primary;
  }
  .input-single-digit {
    @apply border h-10 w-10 bg-secondary text-center rounded-xl;
  }
  .tab {
    @apply mt-2 block border-x-0 border-t-0 border-b-2 border-transparent py-2 w-max text-sm font-medium leading-tight hover:isolate hover:border-transparent focus:isolate focus:border-transparent data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary text-body data-[te-nav-active]:border-primary data-[te-nav-active]:text-primary no-underline;
  }
  
  .accordion-button:not(.collapsed) {
    @apply bg-primary text-white shadow-none;
  }
  .accordion-item:last-of-type {
    @apply rounded-none;
  }
  .vjs-ad-loading .vjs-loading-spinner:before,
  .vjs-ad-loading .vjs-loading-spinner:after,
  .video-js.vjs-custom-waiting .vjs-loading-spinner:before,
  .video-js.vjs-custom-waiting .vjs-loading-spinner:after {
    @apply animate-spin;
  }

  .overflow-x-auto {
    @apply no-scrollbar;
  }
  #___gcse_0 {
    @apply !hidden;
  }
  .static-content.prose a {
    @apply text-link no-underline hover:underline;
  }
  .static-content.prose u {
    @apply no-underline;
  }
  .static-content.prose table,
  .static-content.prose table * {
    @apply my-0;
  }
  .static-content.prose .note {
    @apply border-secondary-dark border-l-[0.5rem] bg-secondary p-4;
  }
  .overlay-row, .overlay-lang-row{
    @apply flex m-0 ml-[-0.75rem] mr-[-0.75rem];
  }
  .overlay-row{
    @apply flex-wrap;
  }
  .absolute-center{
    @apply absolute inset-1/2 -translate-x-1/2 -translate-y-1/2;
  }
  .video-js .vjs-big-play-button {
    @apply !border-none !bg-red-500;
  }
  .video-js .vjs-big-play-button .vjs-icon-placeholder:before {
    @apply !mt-1;
  }
  .math img {
    @apply my-0;
  }
  .ReactCrop .ord-se::after, .ReactCrop .ord-nw::after, .ReactCrop .ord-ne::after, .ReactCrop .ord-sw::after{
    @apply bg-primary;
  }
}
.Toastify > * {
  z-index: 99999999 !important;
}
.react-modal-sheet-backdrop {
  background-color: rgba(0, 0, 0, 0.7) !important;
}
html{
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}
/* default select arrow is small */
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  height: 45px;
  width: 100%;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAMAAACtdX32AAAAdVBMVEUAAAD///8AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAhMdQaAAAAJ3RSTlMAAAECAwQGBwsOFBwkJTg5RUZ4eYCHkJefpaytrsXGy8zW3+Do8vNn0bsyAAAAYElEQVR42tXROwJDQAAA0Ymw1p9kiT+L5P5HVEi3qJn2lcPjtIuzUIJ/rhIGy762N3XaThqMN1ZPALsZPEzG1x8LrFL77DHBnEMxBewz0fJ6LyFHTPL7xhwzWYrJ9z22AqmQBV757MHfAAAAAElFTkSuQmCC);
  background-position: 100%;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 0;
}

svg:not(:host).svg-inline--fa,
svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box;
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
html{
  scroll-behavior: smooth;
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .responsive-grid-system {
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  }
}

.fa {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-2x {
  font-size: 2em;
}

.fa-4x {
  font-size: 4em;
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

:root .fa-flip-horizontal {
  filter: none;
}

.fa-backward-step:before {
  content: "\f048";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-play:before {
  content: "\f04b";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-forward-step:before {
  content: "\f051";
}

.fa-lock:before {
  content: "\f023";
}

.fa-unlock:before {
  content: "\f09c";
}

@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.eot);
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.woff2)
      format("woff2"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.woff)
      format("woff"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.ttf)
      format("truetype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-brands-400.svg#fontawesome)
      format("svg");
}

.fab {
  font-family: "Font Awesome 5 Brands";
  font-weight: 400;
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.eot);
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.eot?#iefix)
      format("embedded-opentype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.woff2)
      format("woff2"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.woff)
      format("woff"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.ttf)
      format("truetype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-regular-400.svg#fontawesome)
      format("svg");
}

@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.eot);
  src: url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.eot?#iefix)
      format("embedded-opentype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.woff2)
      format("woff2"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.woff)
      format("woff"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.ttf)
      format("truetype"),
    url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/webfonts/fa-solid-900.svg#fontawesome)
      format("svg");
}

.fa {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

#react-mathjax-preview-result > img {
  max-width: 300px;
}

#app {
  word-wrap: break-word;
}

/* course page */
.max-three-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

/* breaks on lower screen size than 320 */
@media (max-width: 320px) {
  .responsive-grid-system {
    grid-template-columns: auto;
  }
}
