html[data-variant="doubtnut"][data-theme="dark"] {
  /* Background Colors */
  --bg-primary: #0F0F0F;
  --bg-secondary: #212121;
  --bg-tertiary: #141414;
  
  /* Text Colors */
  --text-default-title: #FFFFFF;
  --text-default-body: #BCBDBD;
  --text-default-caption: #BCBDBD;
  --text-active: #3592FD;
  --text-inactive: #848484;
  --text-static-black: #0F0F0F;
  --text-static-white: #FFFFFF;
  --text-inverted: #0F0F0F;
  --text-success: #04CA8F;
  --text-warning: #FFAB2E;
  --text-error: #FA2946;

  /* Fill Colors */
  --fill-primary-active: #0266DA;
  --fill-secondary-active: #2E3357;
  --fill-inactive: #292929;
  --fill-tertiary-active: #383838;
  --fill-inverted: #FFFFFF;
  --fill-success-bold: #00B17C;
  --fill-success-subtle: #004A34;
  --fill-error-bold: #EB001F;
  --fill-error-subtle: #510D0D;
  --fill-warning-bold: #A86500;
  --fill-warning-subtle: #302A1F;
  --fill-static-black: #1E1E22;
  --fill-static-white: #FFFFFF;
  --fill-pressed-overlay: rgba(0; 0; 0; 0.30);

  /* Event Colors */
  --event-value: #35B58E;
  --event-urgent: #D93C75;
  --event-attention: #F66A05;
  --event-physics: #4949EE;
  --event-chemistry: #AD69E8;
  --event-biology: #0ACB7E;
  --event-maths: #267C96;
  --event-socialscience: #dc2dcc;
  --event-zoology: #f3811b;
  --event-english: #de2c7b;
  --event-mentalability: #0f99fa;
  --event-generic: #809bc9;

  /* Border Colors */
  --border-attention: #F66A05;
  --border-default-active: #FFFFFF;
  --border-brand-active: #0266DA;
  --border-mute-active: #424547;
  --border-inactive: #2E2E2E;
  --border-static-black: #0F0F0F;
  --border-static-white: #FFFFFF;
  --border-success: #009C6E;
  --border-warning: #A86500;
  --border-error: #E80220;
  --border-transparent: transparent;
  --border-event-attention: #F66A05;

 /* Shadows */
 --shadow-lightest: #00000080;
 --shadow-darker: #0000009e;
 --shadow-darkest: #000000d1;

 /* Overlay */
 --overlay: #000000b3;

 /* Sheer Fills */
 --sheer-fills-40: #0f0f0f66;
 --sheer-fills-50: #0f0f0f80;
 --sheer-fills-60: #0f0f0f99;
 --sheer-fills-70: #0f0f0fb3;
 --sheer-fills-inverted-10: #edf2fa1a;
 --sheer-fills-inverted-20: #edf2fa33;
 --sheer-fills-inverted-40: #edf2fa66;
 --sheer-fills-static-white-20: #ffffff33;
 --sheer-fills-static-white-30: #ffffff4d;
 --sheer-fills-static-white-50: #ffffff80;
 --sheer-fills-static-black-20: #00000033;
 --sheer-fills-static-black-30: #0000004d;
 --sheer-fills-static-black-50: #00000080;
}