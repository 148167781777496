html[data-variant="doubtnut"][data-theme="dark"] {
  --app-color-body: #ecf1ff;
  --app-color-body-bg: rgba(38,38,38,1);
  --app-color-link: #3498DB;
  --app-color-highlighted-card: #374151;
  --app-color-heading-bg: #040712;
  --app-color-text-solution-border: #4B5563;

  --app-color-accent: #eb532c;
  
  --app-color-primary-light: rgba(82,82,82,1);
  --app-color-primary: rgba(64,64,64,1); 
  --app-color-primary-dark: rgba(38,38,38,1); 
  
  --app-color-secondary-light: rgba(51,65,85,1);
  --app-color-secondary: rgba(71,85,105,1);
  --app-color-secondary-dark: rgba(100,116,139,1);

  --app-color-badge: #CBD5E1;
  --app-color-badge-bg: #334156;
  --app-color-maths: #388CD9;
  --app-color-physics: #9966CC;
  --app-color-chemistry: #E28B08;
  --app-color-biology: #299215;
  --app-color-science: #299215;
  --app-color-general-science: #299215;
  --app-color-english: #EA4444;
  --app-color-spoken-english: #EA4444;
  --app-color-english-grammar: #EA4444;
  --app-color-hindi: #EA4444;
  --app-color-neet: #194837;
  --app-color-iit-jee: #388CD9;
  --app-color-defence: #35461C;
  --app-color-defence-nda-navy: #765631;
  --app-color-nda: #765631;
  --app-color-navy: #765631;
  --app-color-up-board: #6D541E;
  --app-color-bihar-board: #103D18;
  --app-color-cbse: #1A2950;
  --app-color-cbse-board: #1A2950;
  --app-color-cbse-boards: #1A2950;
  --app-color-mp-board: #750D1E;
  --app-color-jharkhand-board: #1D473A;
  --app-color-haryana-board: #5B4423;
  --app-color-himachal-board: #0D3C57;
  --app-color-chhattisgarh-board: #5C3720;
  --app-color-uttarakhand-board: #195578;
  --app-color-rajasthan-board: #8B5723;
  --app-color-class-6: #196F3D;
  --app-color-class-7: #116466;
  --app-color-class-8: #D35400;
  --app-color-class-9: #4A235A;
  --app-color-class-10: #283747;
  --app-color-class-11: #1B4F72;
  --app-color-class-12: #A04000;
  --app-color-dropper: #1B5E20;
}