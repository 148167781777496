html[data-variant="doubtnut"][data-theme="light"] {
  /* Background Colors */
  --bg-primary: #EDF2FA;
  --bg-secondary: #FFFFFF;
  --bg-tertiary: #E4E9F1;

  /* Text Colors */
  --text-default-title: #1E1E22;
  --text-default-body: #494A4A;
  --text-default-caption: #494A4A;
  --text-active: #025FCA;
  --text-inactive: #757575;
  --text-static-black: #1E1E22;
  --text-static-white: #FFFFFF;
  --text-inverted: #FFFFFF;
  --text-success: #00875E;
  --text-warning: #A54703;
  --text-error: #C7001B;

  /* Fill Colors */
  --fill-primary-active: #0266DA;
  --fill-secondary-active: #D5E8FF;
  --fill-tertiary-active: #D1D1D1;
  --fill-inverted: #1C1D1D;
  --fill-inactive: #E6E6E6;
  --fill-success-bold: #0AA978;
  --fill-success-subtle: #C3E7DC;
  --fill-error-bold: #EA001F;
  --fill-error-subtle: #FFDCDC;
  --fill-warning-bold: #E8900E;
  --fill-warning-subtle: #FFE7C5;
  --fill-static-black: #1E1E22;
  --fill-static-white: #FFFFFF;
  --fill-pressed-overlay: rgba(0; 0; 0; 0.20);

  /* Event Colors */
  --event-value: #35B58E;
  --event-urgent: #D93C75;
  --event-attention: #F66A05;
  --event-physics: #4949EE;
  --event-chemistry: #AD69E8;
  --event-biology: #0ACB7E;
  --event-maths: #267C96;
  --event-socialscience: #dc2dcc;
  --event-zoology: #f3811b;
  --event-english: #de2c7b;
  --event-mentalability: #0f99fa;
  --event-generic: #809bc9;

  /* Border Colors */
  --border-attention: #F66A05;
  --border-default-active: #494A4A;
  --border-brand-active: #0266DA;
  --border-mute-active: #C6C8CC;
  --border-inactive: #DCDCDC;
  --border-static-black: #1E1E22;
  --border-static-white: #FFFFFF;
  --border-success: #089B6E;
  --border-warning: #E8900E;
  --border-error: #EA001F;
  --border-event-attention: #F66A05;
  --border-transparent: transparent;

  /* Shadows */
  --shadow-lightest: #0000001a;
  --shadow-darker: #0000001f;
  --shadow-darkest: #0000001f;

  /* Overlay */
  --overlay: #1e1e2299;

  /* Sheer Fills */
  --sheer-fills-40: #edf2fa66;
  --sheer-fills-50: #edf2fa80;
  --sheer-fills-60: #edf2fa99;
  --sheer-fills-70: #edf2fab3;
  --sheer-fills-inverted-10: #0f0f0f1a;
  --sheer-fills-inverted-20: #0f0f0f33;
  --sheer-fills-inverted-40: #0f0f0f66;
  --sheer-fills-static-white-20: #ffffff33;
  --sheer-fills-static-white-30: #ffffff4d;
  --sheer-fills-static-white-50: #ffffff80;
  --sheer-fills-static-black-20: #00000033;
  --sheer-fills-static-black-30: #0000004d;
  --sheer-fills-static-black-50: #00000080;
}